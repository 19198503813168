body {
  margin: 0;
  font-family: 'Barlow', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

.MuiDialog-paper.MuiDialog-paper.MuiPickersModal-dialogRoot{
  max-width: 325px !important;
}
.MuiDialog-paper.MuiDialog-paper.MuiPickersModal-dialogRoot .MuiPickersModal-dialog{
  margin: 0px;
}
.ag-watermark{
  display: none !important;
}